import { useState } from 'react';
import items from './priceList';
import { CgDropOpacity, CgEditFade, CgEditNoise, CgEditStraight, CgInfo, CgOrganisation, CgShoppingBag } from "react-icons/cg";

function App() {
  const [meters, setMeters] = useState(0);
  const [underLayer, setUnderLayer] = useState(null);
  const [type, setType] = useState(null);
  const [texture, setTexture] = useState(null);
  const [finish, setFinish] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [calculateData, setCalculateData] = useState(null);
  const [pricePerMeters, setPricePerMeters] = useState(null);
  const [salePricePerMeters, setSalePricePerMeters] = useState(null);
  const [implementPrice, setBaseImplementPrice] = useState(null);


  const changeMeters = (value) => {
    setCalculateData(null)
    setPricePerMeters(null)
    setSalePricePerMeters(null)
    setMeters(value)
  };
  
  const changeUnderlayer = (value) => {
    setCalculateData(null)
    setPricePerMeters(null)
    setSalePricePerMeters(null)
    setUnderLayer(value)
  };
  
  const changeTexture = (value) => {
    setCalculateData(null)
    setPricePerMeters(null)
    setSalePricePerMeters(null)
    setTexture(value)
  };
  
  const changeType = (value) => {
    setCalculateData(null)
    setPricePerMeters(null)
    setSalePricePerMeters(null)
    setFinish(null);
    setType(value);
    if (value === "عادی") {
      setFinish("مات");
    }
  };
  
  const changeFinish = (value) => {
    setCalculateData(null)
    setPricePerMeters(null)
    setSalePricePerMeters(null)
    setFinish(value);
  };

  const calculateCost = () => {
    if (underLayer === "گچ/کناف/MDF") {
      if (texture === "مینیمال") {
        setCalculateData(items.chalk.chalk_minimal);
        formula(items.chalk.chalk_minimal);
        implementationCalculator(items.chalk.chalk_minimal);
      } else if (texture === "ایسلا") {
        setCalculateData(items.chalk.chalk_island);
        formula(items.chalk.chalk_island);
        implementationCalculator(items.chalk.chalk_island);
      } else if (texture === "اسپینا") {
        setCalculateData(items.chalk.chalk_spina);
        formula(items.chalk.chalk_spina);
        implementationCalculator(items.chalk.chalk_spina);
      } else if (texture === "رونیک") {
        setCalculateData(items.chalk.chalk_ronic);
        formula(items.chalk.chalk_ronic);
        implementationCalculator(items.chalk.chalk_ronic);
      } else if (texture === "کتان") {
        setCalculateData(items.chalk.chalk_cotton);
        formula(items.chalk.chalk_cotton);
        implementationCalculator(items.chalk.chalk_cotton);
      } else {
        setCalculateData(null);
      }
    } else if (underLayer === "سیمان لیسه ای نرمه کشی شده") {
      if (texture === "مینیمال") {
        setCalculateData(items.cement.cement_minimal);
        formula(items.cement.cement_minimal);
        implementationCalculator(items.cement.cement_minimal);
      } else if (texture === "ایسلا") {
        setCalculateData(items.cement.cement_island);
        formula(items.cement.cement_island);
        implementationCalculator(items.cement.cement_island);
      } else if (texture === "اسپینا") {
        setCalculateData(items.cement.cement_spina);
        formula(items.cement.cement_spina);
        implementationCalculator(items.cement.cement_spina);
      } else if (texture === "رونیک") {
        setCalculateData(items.cement.cement_ronic);
        formula(items.cement.cement_ronic);
        implementationCalculator(items.cement.cement_ronic);
      } else if (texture === "کتان") {
        setCalculateData(items.cement.cement_cotton);
        formula(items.cement.cement_cotton);
        implementationCalculator(items.cement.cement_cotton);
      } else {
        setCalculateData(null);
      }
    } else if (underLayer === "سطح تایلی بتونه شده بدون ناخن") {
      if (texture === "مینیمال") {
        setCalculateData(items.tile.tile_minimal);
        formula(items.tile.tile_minimal);
        implementationCalculator(items.tile.tile_minimal);
      } else if (texture === "ایسلا") {
        setCalculateData(items.tile.tile_island);
        formula(items.tile.tile_island);
        implementationCalculator(items.tile.tile_island);
      } else if (texture === "اسپینا") {
        setCalculateData(items.tile.tile_spina);
        formula(items.tile.tile_spina);
        implementationCalculator(items.tile.tile_spina);
      } else if (texture === "رونیک") {
        setCalculateData(items.tile.tile_ronic);
        formula(items.tile.tile_ronic);
        implementationCalculator(items.tile.tile_ronic);
      } else if (texture === "کتان") {
        setCalculateData(items.tile.tile_cotton);
        formula(items.tile.tile_cotton);
        implementationCalculator(items.tile.tile_cotton);
      } else {
        setCalculateData(null);
      }
    } else {

    }
  };

  const formula = (data) => {

    const hardnerSale = (data.mid + data.base + data.subbase) * data.hardner * items.pricesSale.hardner;
    const hardner = (data.mid + data.base + data.subbase) * data.hardner * items.pricesCustomer.hardner;

    const priceWithoutMatteAndPoly = (data.primer * items.pricesCustomer.primer) + (data.mesh * items.pricesCustomer.mesh) + (data.mid * items.pricesCustomer.mid) + (data.base * items.pricesCustomer.base) + (data.subbase * items.pricesCustomer.subbase) + (data.siloxan * items.pricesCustomer.siloxan) + (data.color * items.pricesCustomer.color) + (data.lack * items.pricesCustomer.lack) + hardner;
    const salepriceWithoutMatteAndPoly = (data.primer * items.pricesSale.primer) + (data.mesh * items.pricesSale.mesh) + (data.mid * items.pricesSale.mid) + (data.base * items.pricesSale.base) + (data.subbase * items.pricesSale.subbase) + (data.siloxan * items.pricesSale.siloxan) + (data.color * items.pricesSale.color) + (data.lack * items.pricesSale.lack) + hardnerSale;

    if (type === "ضدآب - یک دست") {
      if (finish === "مات") {

        const fullprice = priceWithoutMatteAndPoly + (data.polyurtan_matte * items.pricesCustomer.polyurtan_matte);
        setPricePerMeters(fullprice);

        const saleprice = salepriceWithoutMatteAndPoly + (data.polyurtan_matte * items.pricesSale.polyurtan_matte);
        setSalePricePerMeters(saleprice);

      } else if (finish === "براق") {

        const fullprice = priceWithoutMatteAndPoly + (data.polyurtan_shine * items.pricesCustomer.polyurtan_shine);
        setPricePerMeters(fullprice);

        const saleprice = salepriceWithoutMatteAndPoly + (data.polyurtan_shine * items.pricesSale.polyurtan_shine);
        setSalePricePerMeters(saleprice);

      }
    } else if (type === "ضدآب - دو دست") {
      if (finish === "مات") {

        const fullprice = priceWithoutMatteAndPoly + (data.polyurtan_matte * 2 * items.pricesCustomer.polyurtan_matte);
        setPricePerMeters(fullprice);

        const saleprice = salepriceWithoutMatteAndPoly + (data.polyurtan_matte * 2 * items.pricesSale.polyurtan_matte);
        setSalePricePerMeters(saleprice);

      } else if (finish === "براق") {

        const fullprice = priceWithoutMatteAndPoly + (data.polyurtan_shine * 2 * items.pricesCustomer.polyurtan_shine);
        setPricePerMeters(fullprice);

        const saleprice = salepriceWithoutMatteAndPoly + (data.polyurtan_shine * 2 * items.pricesSale.polyurtan_shine);
        setSalePricePerMeters(saleprice);

      }
    } else if (type === "عادی") {

      setPricePerMeters(priceWithoutMatteAndPoly);
      setSalePricePerMeters(salepriceWithoutMatteAndPoly);
      console.log("Addi Set Shode: " + salepriceWithoutMatteAndPoly);

    } else {

      setPricePerMeters(null);
      setSalePricePerMeters(null);
      //implementationCalculator(data, fullprice, saleprice)

    }
  };

  const implementationCalculator = (data) => {
    setBaseImplementPrice(0);
    setBaseImplementPrice(items.priceImplementation.base);
    const texturePrice = texture === "کتان" ? items.priceImplementation.texture_cotton : texture === "رونیک" ? items.priceImplementation.texture_ronic : texture === "ایسلا" ? items.priceImplementation.texture_isla : texture === "اسپینا" ? items.priceImplementation.texture_spina :0;
    if (type === "ضدآب - یک دست") {
      setBaseImplementPrice(items.priceImplementation.base + items.priceImplementation.polyurtan + (data.mesh !== 0 ? items.priceImplementation.meshing : 0) + (data.siloxan !== 0 ? items.priceImplementation.siloxan : 0) + texturePrice );
    }
    if (type === "ضدآب - دو دست") {
      setBaseImplementPrice(items.priceImplementation.base + (items.priceImplementation.polyurtan * 2) + (data.mesh !== 0 ? items.priceImplementation.meshing : 0) + (data.siloxan !== 0 ? items.priceImplementation.siloxan : 0) + texturePrice);
    }
    if (type === "عادی") {
      setBaseImplementPrice(items.priceImplementation.base + (data.mesh !== 0 ? items.priceImplementation.meshing : 0) + (data.siloxan !== 0 ? items.priceImplementation.siloxan : 0) + texturePrice );
    }
  };

  const calculateDifference = (value1, value2) => {
    return value1 - value2;
  };

  const printNumbers = () => {
    const texturePrice = 0;
    if (meters <= items.priceImplementation.min_meters) {
      const finalPrice = ( ( (pricePerMeters + implementPrice) + texturePrice + items.implementCosts.profit + items.implementCosts.comission - calculateDifference(pricePerMeters, salePricePerMeters) + items.implementCosts.launch + items.implementCosts.usage ) + ((pricePerMeters + implementPrice) + texturePrice + items.implementCosts.profit + items.implementCosts.comission - calculateDifference(pricePerMeters, salePricePerMeters) + items.implementCosts.launch + items.implementCosts.usage) * items.priceImplementation.telorance/100 );
      const prices = {
        perMeter: finalPrice.toLocaleString(),
        fullPrice: (meters * finalPrice).toLocaleString()
      };
      console.log(prices);
      return prices
    } else if (meters > items.priceImplementation.max_meters) {
      const finalPrice = ( ( (pricePerMeters + implementPrice) + texturePrice + items.implementCosts.profit + items.implementCosts.comission - calculateDifference(pricePerMeters, salePricePerMeters) + items.implementCosts.launch + items.implementCosts.usage ) - items.priceImplementation.discount );
      const prices = {
        perMeter: finalPrice.toLocaleString(),
        fullPrice: (meters * finalPrice).toLocaleString()
      };
      return prices
    } else {
      const finalPrice = ( ( (pricePerMeters + implementPrice) + texturePrice + items.implementCosts.profit + items.implementCosts.comission - calculateDifference(pricePerMeters, salePricePerMeters) + items.implementCosts.launch + items.implementCosts.usage ) );
      const prices = {
        perMeter: finalPrice.toLocaleString(),
        fullPrice: (meters * finalPrice).toLocaleString()
      };
      return prices
    }
  };

  return (
    <>
    <div className='font-bold flex flex-row items-center justify-center mt-4 mb-4'>
      محاسبه قیمت میکروسمنت
    </div>
    <div className="flex flex-col justify-center items-center border m-4 p-4 rounded">
      <div className='flex flex-col items-start justify-center w-full'>
        <label for="meters" className='flex flex-row items-center'><CgOrganisation className='me-2' /> متراژ سطح:</label>
        <div className='border rounded p-1 mb-2 w-full'>
          <input type="text"name="meters" pattern="[0-9]*" className="w-full" placeholder='متراژ' onChange={e => changeMeters(e.target.value)} onKeyPress={(e) => {
        // Prevent non-numeric input
        if (!/[0-9]/.test(e.key)) {
          e.preventDefault();
        }
      }}/>
        </div>

        <label for="underlayer" className='flex flex-row items-center'><CgEditFade className='me-2' /> زیرسازی:</label>
        <div className='border rounded p-1 mb-2 w-full'>
          <select name="underlayer" className='w-full bg-transparent' onChange={e => changeUnderlayer(e.target.value)}>
            <option value="زیرسازی" disabled selected>انتخاب کنید</option>
            <option value="گچ/کناف/MDF">گچ/کناف/MDF</option>
            <option value="سیمان لیسه ای نرمه کشی شده">سیمان لیسه ای نرمه کشی شده</option>
            <option value="سطح تایلی بتونه شده بدون ناخن">سطح تایلی بتونه شده بدون ناخن</option>
          </select>
        </div>

        <label for="texture" className='flex flex-row items-center'><CgEditNoise className='me-2' /> بافت:</label>
        <div className='border rounded p-1 mb-2 w-full'>
          <select name="texture" className='w-full bg-transparent' onChange={e => changeTexture(e.target.value)}>
            <option value="بافت" disabled selected>انتخاب کنید</option>
            <option value="مینیمال">مینیمال</option>
            <option value="ایسلا">ایسلا</option>
            <option value="اسپینا">اسپینا</option>
            <option value="رونیک">رونیک</option>
            <option value="کتان">کتان</option>
          </select>
        </div>

        <label for="type" className='flex flex-row items-center'><CgEditStraight className='me-2' /> نوع:</label>
        <div className='border rounded p-1 mb-2 w-full'>
          <select name="type" className='w-full bg-transparent' defaultValue={null} onChange={e => changeType(e.target.value)}>
            <option className="font" value="نوع" disabled selected>انتخاب کنید</option>
            <option className="font" value="عادی">عادی</option>
            <option className="font" value="ضدآب - یک دست">ضدآب - یک دست</option>
            <option className="font" value="ضدآب - دو دست"> ضدآب - دو دست</option>
          </select>
        </div>
        {type === "ضدآب - یک دست" || type === "ضدآب - دو دست" ?
        <>
        <label for="finish" className='flex flex-row items-center'><CgDropOpacity className='me-2' /> فینیش:</label>
        <div className='border rounded p-1 mb-2 w-full'>
          <select name="finish" className='w-full bg-transparent' onChange={e => changeFinish(e.target.value)}>
            <option value="تاپ کوت" disabled selected>انتخاب کنید</option>
            <option value="مات">مات</option>
            <option value="براق">براق</option>
          </select>
        </div>
        </>
        :
        <>
        <label for="finish" className='flex flex-row items-center'><CgDropOpacity className='me-2' /> فینیش:</label>
        <div className='border rounded p-1 mb-2 w-full bg-gray-100 cursor-not-allowed'>
          <select name="finish" className='w-full bg-transparent disabled:bg-gray-100  cursor-not-allowed' disabled>
            <option value="تاپ کوت" disabled>انتخاب کنید</option>
            <option value="مات" selected>مات</option>
            <option value="براق">براق</option>
          </select>
        </div>
        </>
        }
      </div>
      <div className='flex flex-col w-full items-start justify-center p-2 border rounded'>
        <div className='flex flex-row items-center'>
          <CgOrganisation className='me-2' />
          متراژ: <div className='font ms-2'>{meters ? meters + " متر مربع" : "-"}</div>
        </div>
        <div className='flex flex-row items-center'>
        <CgEditFade className='me-2' />
          زیرسازی: <div className='font ms-2'>{underLayer ? underLayer : "-"}</div>
        </div>
        <div className='flex flex-row items-center'>
        <CgEditNoise className='me-2' />
          بافت: <div className='font ms-2'>{texture ? texture : "-"}</div>
        </div>
        <div className='flex flex-row items-center'>
        <CgEditStraight className='me-2' />
          نوع: <div className='font ms-2'>{type ? type : "-"}</div>
        </div>
        <div className='flex flex-row items-center'>
        <CgDropOpacity className='me-2' />
          فینیش: <div className='font ms-2'>{finish ? finish : "-"}</div>
        </div>
      </div>
      <div className='w-full text-center'>
        {meters && underLayer && type && texture && finish ? 
        <div onClick={() => calculateCost()} className='font cursor-pointer bg-teal-500 text-white border-blue-500 w-full rounded p-2 mt-4'>
          محاسبه
        </div>
        : 
        <div className='font cursor-not-allowed bg-gray-200 text-white border w-full rounded p-2 mt-4'>
          محاسبه
        </div>
        }
      </div>
      <div className='flex flex-col w-full'>
        {pricePerMeters && salePricePerMeters && meters ? 
        <>
        <div className='flex flex-col items-start justify-start w-full bg-cyan-600 text-white mt-4 rounded'>
{/*           
          <div className='p-2 w-full'>
            <div className=''>متریال:</div>
            <div>قیمت هر متر متریال: {(pricePerMeters).toLocaleString()}</div>
            {meters <= 20 ?
            <div>
              جمع کل متریال (با ضریب کمتر از 20 متر): {meters <= 20 ? (meters * pricePerMeters + (meters * pricePerMeters * items.priceImplementation.telorance/100)).toLocaleString() : (meters * pricePerMeters).toLocaleString()}
            </div>
            :
            <div>
              جمع کل متریال: {(meters * pricePerMeters).toLocaleString()}
            </div>
            }
            
          </div>
          <div className='w-full h-1 bg-white my-2'></div>
           */}
          
          {/* <div className='p-2 w-full'>
            <div className=''>اجرا:</div>
            <div>قیمت اجرای هر مترمربع: {(implementPrice).toLocaleString()}</div>
            <div>
              ابزار مصرفی + پذیرایی: {(items.implementCosts.usage + items.implementCosts.launch).toLocaleString()}
            </div>
            <div>هزینه تمام شده هر مترمربع اجرا: {(items.implementCosts.launch + items.implementCosts.usage + implementPrice).toLocaleString()}</div>
            {meters <= 20 ?
            <div>
              جمع کل اجرا (با ضریب کمتر از 20 متر): {meters <= 20 ? (meters * (items.implementCosts.launch + items.implementCosts.usage + implementPrice) + (meters * (items.implementCosts.launch + items.implementCosts.usage + implementPrice) * items.priceImplementation.telorance/100)).toLocaleString() : (meters * (items.implementCosts.launch + items.implementCosts.usage + implementPrice)).toLocaleString()}
            </div>
            :
            <div>
                جمع کل اجرا: {(meters * (items.implementCosts.launch + items.implementCosts.usage + implementPrice)).toLocaleString()}
            </div>
            }
            </div> 
            <div className='w-full h-1 bg-white my-2'></div>
            */}
          

          <div className='p-2 w-full'>
          <div className='flex flex-row items-center'><CgShoppingBag className='me-2' /> مجموع: </div>
          <div className='text-gray-300 text-sm'>{meters > 200 ? "مبلغ " + items.priceImplementation.discount.toLocaleString() + "به ازای هر متر مربع برای متراژ بالای 200 مترمربع تخفیف لحاظ گردیده است." : null}</div>

            <div className='flex flex-row items-center justify-between'>قیمت هر متر مربع: <div className='font ms-2'>{printNumbers().perMeter} تومان</div>
            </div>
            {/* <div>سود مجموعه: {(items.implementCosts.profit + items.implementCosts.comission - calculateDifference(pricePerMeters, salePricePerMeters)).toLocaleString()}</div> */}
            <div className='flex flex-row items-center justify-between'>
              قیمت کل: <div className='font ms-2'>{printNumbers().fullPrice} تومان</div>
            </div>
            {/* <div>قیمت اجرا و متریال بدون احتساب اسکیل کار: {(meters * (pricePerMeters + implementPrice + items.implementCosts.launch + items.implementCosts.usage + items.implementCosts.profit + items.implementCosts.comission - calculateDifference(pricePerMeters, salePricePerMeters))).toLocaleString()}</div>
            <div>قیمت اجرا و متریال با احتساب اسکیل کار: {meters <= 20 ? (meters * (pricePerMeters + implementPrice + items.implementCosts.launch + items.implementCosts.usage + items.implementCosts.profit + items.implementCosts.comission - calculateDifference(pricePerMeters, salePricePerMeters)) + (meters * (pricePerMeters + implementPrice + items.implementCosts.launch + items.implementCosts.usage + items.implementCosts.profit + items.implementCosts.comission - calculateDifference(pricePerMeters, salePricePerMeters)) * items.priceImplementation.telorance/100)).toLocaleString() : (meters * (pricePerMeters + implementPrice + items.implementCosts.launch + items.implementCosts.usage + items.implementCosts.profit + items.implementCosts.comission - calculateDifference(pricePerMeters, salePricePerMeters))).toLocaleString()}</div> */}
          </div>
        </div>
        <div className='flex flex-row items-center justify-start w-full bg-gray-700 text-white mt-4 rounded text-xs p-2'>
          <CgInfo className='mx-2' size={24} />
          <div>
          <p className='mb-2'>
          قیمت‌ها بصورت حدودی محاسبه گردیده و برآورد دقیق‌تر پس از بازدید از پروژه اعلام خواهد شد.
          </p>
          <p>برای اطلاعات بیشتر با شماره 09137240096 تماس حاصل فرمایید.</p>
          </div>
        </div>
        </>
        : 
        <>
        <div className='flex flex-col items-start justify-start w-full bg-cyan-600 text-white mt-4 rounded'>       
          <div className='p-2 w-full'>
          <div className='flex flex-row items-center'><CgShoppingBag className='me-2' /> مجموع: </div>
            <div className='flex flex-row items-center justify-between'>
              قیمت هر متر مربع: <div className='font ms-2'>0 تومان</div>
            </div>
            <div className='flex flex-row items-center justify-between'>
              قیمت کل: <div className='font ms-2'>0 تومان</div>
            </div>
          </div>
        </div>
        </>
        }
      </div>
    </div>
    </>
  );
}

export default App;
